<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchFormRef" @keyup.enter.native="getMtrbsNow()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="我司货号" prop="prod_no">
              <el-input size="small" v-model="searchForm.prod_no" placeholder="请填写我司货号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="客户货号" prop="prod_cust_no">
              <el-input size="small" v-model="searchForm.prod_cust_no" placeholder="请填写客户货号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="材料编号:" prop="mtrb_no">
              <el-input size="small" v-model.trim="searchForm.mtrb_no" placeholder="请填写材料编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="16">
            <el-form-item label="材料名称:" prop="mtrb_name">
              <el-input size="small" v-model.trim="searchForm.mtrb_name" placeholder="请填写材料名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getMtrbsNow()" class="vg_ml_16">查询 </el-button>
              <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="vd_button_group vg_mtb_16">
      <el-row class="vd_mar10">
        <el-col :md="16">
          <el-button size="mini" type="danger" @click="clear">取消选择</el-button>
          <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn">确认选择</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :md="24">
        <el-table
          class="vg_cursor"
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loadFlag"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="序号" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="我司货号" prop="prod_no"></el-table-column>
          <el-table-column label="货号后缀" prop="prod_poststfix"></el-table-column>
          <el-table-column label="材料编号" prop="mtrb_no"></el-table-column>
          <el-table-column label="客户货号" prop="prod_cust_no"></el-table-column>
          <el-table-column label="材料名称" prop="mtrb_name" show-overflow-tooltip></el-table-column>
          <el-table-column label="材料规格" prop="mtrb_spec"></el-table-column>
          <el-table-column label="需申购数量" prop="scon_mtrb_apply"></el-table-column>
          <el-table-column label="单价" prop="prod_mtrb_price" />
          <el-table-column label="供应商简称" prop="supp_abbr"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from '@api/request';
import { sconAPI } from '@api/modules/scon';
import { useSuppMixin } from '@/assets/js/mixin/initMixin.js';

export default {
  name: 'MtrbImport',
  mixins: [useSuppMixin],
  props: {
    supp_id: Number,
    scon_id: Number
  },
  components: {},
  data() {
    return {
      tableData: [],
      searchForm: {
        prod_no: null, //我司货号
        prod_cust_no: null, //客户货号
        mtrb_no: null,
        mtrb_name: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      mtrbTypes: [],
      contry: []
    };
  },
  methods: {
    initData() {
      this.getMtrbsList();
    },
    getMtrbsList() {
      get(sconAPI.getSconMtrbBySuppId, {
        supp_id: this.supp_id,
        scon_id: this.scon_id,
        prod_no: this.searchForm.prod_no,
        prod_cust_no: this.searchForm.prod_cust_no,
        mtrb_name: this.searchForm.mtrb_name,
        mtrb_no: this.searchForm.mtrb_no
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },

    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.mtrb_id;
    },
    // 刷新
    buttonRefresh() {
      this.$refs.searchFormRef.resetFields();
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
    },
    // 查询方法
    getMtrbsNow() {
      this.loadFlag = true;
      this.currentPage = 1;
      this.getMtrbsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childChanel');
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childConfirm', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
