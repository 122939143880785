<template>
  <div class="modrEditMain">
    <AddHeader
      :isShow="isShow"
      :btn="btn"
      :special="true"
      :strForm="{ status: modrForm.status }"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit"
      @refresh="buttonRefresh"
    />
    <el-row>
      <el-col>
        <el-form :model="modrForm" ref="modrForm" label-width="120px" size="mini" :rules="modrFormRules" :disabled="isShow">
          <el-row>
            <el-col :md="8">
              <el-form-item label="销售合同号" prop="scon_no">
                <el-input maxlength="8" v-model="modrForm.scon_no" placeholder="暂无销售合同号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="下单状态" prop="modr_stat">
                <el-select v-model="modrForm.modr_stat" :disabled="modrForm.status === 2" placeholder="请选择下单状态">
                  <el-option :value="0" label="未下单" v-if="modrForm.status !== 2"></el-option>
                  <el-option :value="1" label="已下单"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="合同号备注" prop="modr_note">
                <el-input v-model.trim="modrForm.modr_note" maxlength="10" show-word-limit placeholder="请填写单据提示"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="供应商简称" prop="supp_id">
                <el-select disabled v-model="modrForm.supp_id" placeholder="请选择供应商简称" filterable>
                  <el-option v-for="item in suppOptionList" :key="item.supp_id" :value="item.supp_id" :label="item.supp_abbr"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="公司抬头" prop="cptt_id">
                <el-select v-model="modrForm.cptt_id" placeholder="请选择公司抬头">
                  <el-option v-for="item in cpttOptionList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="合同日期" prop="modr_cndate">
                <el-date-picker v-model="modrForm.modr_cndate" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="客户简称" prop="cust_abbr">
                <el-select v-model="modrForm.cust_abbr" placeholder="请选择" filterable clearable>
                  <el-option v-for="item in custList" :key="item.value" :value="item.label" :label="item.label"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="最终客户" prop="cust_bid">
                <el-select v-model="modrForm.cust_bid" clearable filterable placeholder="请选择">
                  <el-option v-for="item in custList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="要求交货日期" prop="modr_pedate">
                <el-date-picker v-model="modrForm.modr_pedate" @change="peDateChange" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="销售经办人" prop="cust_stff_id">
                <el-select @change="custStffIdChange" v-model="modrForm.cust_stff_id" placeholder="请选择外销经办人" filterable>
                  <el-option v-for="item in stffOptionList" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="销售所属部门" prop="cust_dept_id">
                <el-select v-model="modrForm.cust_dept_id" placeholder="请选择外销所属部门" filterable>
                  <el-option v-for="item in deptOptionList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="工厂账期" prop="supp_creday">
                <el-input
                  v-model="modrForm.supp_creday"
                  placeholder="请填写工厂账期"
                  @change="credayChange"
                  maxlength="3"
                  @input="modrForm.supp_creday = helper.pureNumber(modrForm.supp_creday)"
                  show-word-limit
                >
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="采购经办人" prop="modr_stff_id">
                <el-select @change="podrStffIdChange" v-model="modrForm.modr_stff_id" placeholder="请选择采购经办人" filterable>
                  <el-option v-for="item in stffOptionList" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="采购所属部门" prop="modr_dept_id">
                <el-select v-model="modrForm.modr_dept_id" placeholder="请选择采购所属部门" filterable>
                  <el-option v-for="item in deptOptionList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="预计付款日期" prop="modr_ppdate">
                <el-date-picker v-model="modrForm.modr_ppdate" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="货款金额" prop="modr_prtotal">
                <el-input disabled v-model="modrForm.modr_prtotal" placeholder="暂无货款金额">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="费用金额" prop="modr_fetotal">
                <el-input disabled v-model="modrForm.modr_fetotal" placeholder="暂无费用金额">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="结算方式" prop="modr_payway">
                <el-select v-model="modrForm.modr_payway" placeholder="请选择结算方式">
                  <el-option :value="1" label="我司结算"></el-option>
                  <el-option :value="2" label="工厂结算"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="合同总额" prop="modr_total">
                <el-input disabled v-model="modrForm.modr_total" placeholder="暂无合同总额">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="是否已托收" prop="modr_wiped">
                <el-select v-model="modrForm.modr_wiped" placeholder="请选择是否已托收">
                  <el-option :value="0" label="未托收"></el-option>
                  <el-option :value="1" label="已托收"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="草拟导入验货" prop="modr_inspection">
                <el-select v-model="modrForm.modr_inspection" placeholder="请选择">
                  <el-option v-for="item in [{ value: 1, label: '是' }]" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8 vg_button_group">
            <el-col :md="8">
              <el-form-item label="确认发货" prop="confirm_shipment">
                <el-select v-model="modrForm.confirm_shipment" placeholder="请选择" disabled>
                  <el-option
                    v-for="item in [
                      { value: 0, label: '未发货' },
                      { value: 1, label: '部分发货' },
                      { value: 2, label: '已发货' }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="AQL标准" prop="aql_standard">
                <el-select clearable v-model="modrForm.aql_standard" placeholder="请选择">
                  <el-option
                    v-for="item in [
                      { value: '0.65', label: '0.65' },
                      { value: '1.0', label: '1.0' },
                      { value: '1.5', label: '1.5' },
                      { value: '2.5', label: '2.5' },
                      { value: '4.0', label: '4.0' },
                      { value: '6.0', label: '6.0' }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="客户订单号" prop="scon_cust_no">
                <el-input v-model="modrForm.scon_cust_no" maxlength="20" placeholder="暂无客户订单号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8 vg_button_group">
            <el-col>
              <ModrMtrbList
                :isShow="isShow"
                :modrForm="modrForm"
                :scon_id="modrForm.scon_id"
                :suppOptionList="suppOptionList"
                :podrOptionList="podrOptionList"
                ref="modrMtrbListRef"
                @mtrbPriceChange="mtrbPriceChange"
              />
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-tabs v-model="activeName">
                <el-tab-pane :key="'second'" label="条款明细" name="second">
                  <ModrClauInfo :isShow="isShow" :modrForm="modrForm" @sconClauChange="sconClauChange" />
                </el-tab-pane>
                <el-tab-pane label="费用明细" name="first" :key="'first'">
                  <ModrFeesList :modrForm="modrForm" @feetPriceChange="feetPriceChange" ref="modrFeeListRef" />
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import ModrMtrbList from './Component/ModrMtrbList.vue';
import ModrFeesList from './Component/ModrFeesList.vue';
import ModrClauInfo from './Component/ModrClauInfo.vue';
import { postNoCatch } from '@/api/request';
import { modrAPI } from '@/api/modules/modr';
import { useCpttMixin, useDeptMixin, useStffMixin, useSuppMixin } from '@/assets/js/mixin/initMixin';
import ImportModr from './Component/ImportModr.vue';
import { getCustomerInfo, getDept } from '@api/public';
import { getArrayIds } from '@assets/js/arrayUtils';
import AddHeader from '@/views/component/addHeader.vue';
import { BigNumber } from 'bignumber.js';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'modrEditMain',
  mixins: [useSuppMixin, useCpttMixin, useStffMixin, useDeptMixin],
  components: {
    AddHeader,
    editHeader,
    inputUser,
    ModrMtrbList,
    ModrFeesList,
    ModrClauInfo,
    ImportModr
  },
  data() {
    return {
      isShow: false,
      activeName: 'second',
      btn: { edit: true },
      stffForm: {},
      modrForm: {
        modr_id: null,
        scon_no: null, //销售合同号
        modr_stat: 0, //下单状态
        modr_note: null, //单据提示
        supp_id: null, //供应商
        cptt_id: null, //公司信息
        modr_cndate: null, //合同日期
        cust_abbr: null, //客户简称
        scon_cust_no: null, //客户订单号
        cust_stff_id: null, //外销经办人
        cust_dept_id: null, //外销所属部门
        supp_creday: null, //工厂账期
        modr_stff_id: null, //采购经办人
        modr_dept_id: null, //采购所属部门
        modr_ppdate: null, //预计付款日期
        modr_prtotal: '0.0000', //货款金额
        modr_fetotal: '0.00', //费用金额
        modr_total: '0.00', //合同总额
        modr_payway: 2, //结算方式
        modr_wiped: 0, //是否依托收
        aql_standard: 0,
        modr_fees_list: [],
        modr_mtrb_list: [],
        scon_id: null
      },
      modrFormRules: {
        supp_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cptt_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_bid: [{ required: true, message: ' ', trigger: 'blur' }],
        modr_cndate: [{ required: true, message: ' ', trigger: 'blur' }],
        modr_pedate: [{ required: true, message: ' ', trigger: 'blur' }],
        modr_ppdate: [{ required: true, message: ' ', trigger: 'blur' }],
        modr_stff_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_stff_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_dept_id: [{ required: true, message: ' ', trigger: 'blur' }],
        modr_dept_id: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      podrOptionList: [],
      modrMtrbListCopy: [],
      dialogVisible1: false,
      isBussinessDept: false,
      custList: []
    };
  },
  watch: {
    //费用金额 改变计算总金额
    'modrForm.modr_fetotal': {
      handler(newVal, oldVal) {
        this.modrForm.modr_total = new BigNumber(Number(newVal) + Number(this.modrForm.modr_prtotal)).toFixed(2);
      }
    },
    //货款金额 改变计算总金额
    'modrForm.modr_prtotal': {
      handler(newVal, oldVal) {
        this.modrForm.modr_total = new BigNumber(Number(newVal) + Number(this.modrForm.modr_fetotal)).toFixed(2);
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      let syncData = this.$route.params.syncData;
      if (syncData) {
        this.modrForm = syncData;
        this.modrForm.modr_payway = 2; //工厂结算
        this.stffForm.user_id = this.modrForm.user_id;
        this.stffForm.stff_id = this.modrForm.stff_id;
        this.stffForm.stff_name = this.modrForm.stff_name;
        this.stffForm.dept_id = this.modrForm.dept_id;
        this.stffForm.dept_name = this.modrForm.dept_name;
        this.stffForm.dept_team_id = this.modrForm.dept_team_id;
        this.stffForm.dept_team_name = this.modrForm.dept_team_name;
      }
      this.custList = await getCustomerInfo({ status: 2, type: 'abbr' });
      let fuliao = await getDept('辅料部');
      this.isBussinessDept = getArrayIds([...fuliao], 'value').includes(Number(this.$cookies.get('userInfo').dept_id));
    },
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.show = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs.modrForm.validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    saveInfo() {
      let modrForm = JSON.parse(JSON.stringify(this.modrForm));
      modrForm.modr_cndate = this.helper.getNewDate(modrForm.modr_cndate); //合同日期
      modrForm.modr_pedate = this.helper.getNewDate(modrForm.modr_pedate); //要求交货日期
      modrForm.modr_ppdate = modrForm.modr_ppdate ? this.helper.getNewDate(modrForm.modr_ppdate) : 0; //预计付款日期
      modrForm.modr_mtrb_list.forEach(item => {
        item.modr_mtrb_pedate = this.helper.getNewDate(item.modr_mtrb_pedate);
        item.modr_mtrb_redate = this.helper.getNewDate(item.modr_mtrb_redate);
        //材料列表数据进行处理
        item.podr_id = this.podrOptionList.find(({ podr_no }) => podr_no === item.podr_no)?.podr_id;
      });
      postNoCatch(modrAPI.addMtrbToModr, modrForm).then(async ({ data }) => {
        await this.$EventBus.$emit('close_current_tags');
        await this.jump('/modr_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 128,
              form_id: data.form_id
            })
          )
        });
      });
    },
    // 生成材料采购变更单弹框关闭
    generateCancel() {
      this.dialogVisible1 = false;
    },
    // 外销经办人
    custStffIdChange(val) {
      for (let i = 0; i < this.stffOptionList.length; i++) {
        if (this.stffOptionList[i].stff_id === val) {
          this.modrForm.cust_dept_id = this.stffOptionList[i].dept_id;
        }
      }
    },
    // 采购经办人
    podrStffIdChange(val) {
      for (let i = 0; i < this.stffOptionList.length; i++) {
        if (this.stffOptionList[i].stff_id === val) {
          this.modrForm.modr_dept_id = this.stffOptionList[i].dept_id;
        }
      }
    },
    // 要求交货日期 计算
    peDateChange(val) {
      if (val) {
        this.modrForm.modr_ppdate = this.helper.toTimeVal(val) * 1000 + this.modrForm.supp_creday * 86400000;
      }
      this.modrForm.modr_mtrb_list.forEach(item => {
        item.modr_mtrb_pedate = val ? this.helper.toTimeVal(val) * 1000 : null;
      });
    },
    // 工厂账期 计算
    credayChange() {
      if (this.modrForm.modr_pedate) {
        this.modrForm.modr_ppdate = this.helper.toTimeVal(this.modrForm.modr_pedate) * 1000 + this.modrForm.supp_creday * 86400000;
      }
    },
    //货款款金额 计算
    mtrbPriceChange(val) {
      this.modrForm.modr_prtotal = new BigNumber(val).toFixed(4);
    },
    //费用明细 改变
    feetPriceChange(value) {
      this.modrForm.modr_fetotal = new BigNumber(value).toFixed(4);
    },
    //导入验货标准货 条款内容
    sconClauChange({ type, value }) {
      if (type === '验货标准') {
        this.modrForm.modr_standard = value.clau_content;
      } else if (type === '条款内容') {
        this.modrForm.clau_content = value.clau_content;
      }
    }
  }
};
</script>

<style scoped>
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}

.vd_edhea {
  position: relative;
}
</style>
