<template>
  <div class="modrProdList" v-loading="partLoadFlag">
    <div class="vg_mb_16 flexV">
      <el-button plain size="small" type="primary" @click="clickImport">导入材料</el-button>
      <el-button plain size="small" type="danger" @click="deleteMtrb">删除</el-button>
      <el-button v-if="isCopy" :disabled="false" size="small" type="warning" @click="copySelectedColumnsToClipboard"> 辅料部专用复制</el-button>
      <el-button v-if="notFuliao" size="small" type="warning" @click="split()">拆分</el-button>
      <el-form :disabled="false">
        <div v-if="modrForm.status === 2">
          实际交货日期:
          <el-date-picker
            v-model="sampleSubmissionDate"
            class="vg_ml_8 vg_mr_8 topDateRangeSearch"
            placeholder="请选择"
            size="small"
            type="date"
            value-format="timestamp"
          />
          <el-button plain size="small" type="success" @click="fillSubTableDate">发货</el-button>
          <el-select v-model="search" class="vg_ml_8" clearable filterable placeholder="选择以搜索收货工厂" size="small" style="width: 300px">
            <el-option
              v-for="(item, index) in arrayDeRepeat(modrForm.modr_mtrb_list.map(x => x.supp_babbr))"
              :key="item + index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </el-form>
      <!--<el-input v-model="search" size="small" class="vg_ml_8" style="width: 300px" placeholder="输入以搜索收货工厂" show-word-limit> </el-input>-->
    </div>
    <DynamicUTable
      :form="modrForm"
      :is-show="isShow"
      ref="multiTable"
      :columns="subTableProperties"
      :showSummary="true"
      :need-pagination="false"
      :needChangeColumnProperties="true"
      :isCopyColumn="true"
      :summariesColumns="['mtrb_num', 'mtrb_bnum', 'prod_mtrb_price', 'mtrb_total_price']"
      :table-data="tableData"
      @selection-change="handleSelectionChange"
    >
      <template v-slot:mtrb_ilk="scope">
        <el-form-item :prop="'modr_mtrb_list.' + scope.$index + '.mtrb_ilk'" :rules="[{ required: true }]" label-width="0">
          <el-select v-model="scope.row.mtrb_ilk" clearable filterable placeholder="请选择产品类型" size="small">
            <el-option v-for="item in ilkList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:mtrb_num="scope">
        <el-form-item :prop="'modr_mtrb_list.' + scope.$index + '.mtrb_num'" label-width="0">
          <el-input
            v-model="scope.row.mtrb_num"
            maxlength="11"
            placeholder="请填写数量"
            show-word-limit
            @change="val => mtrbPriceChange(scope.$index)"
            @input="scope.row.mtrb_num = keepNumber(scope.row.mtrb_num)"
          >
          </el-input>
        </el-form-item>
      </template>
      <template v-slot:mtrb_bnum="scope">
        <el-form-item :prop="'modr_mtrb_list.' + scope.$index + '.mtrb_bnum'" label-width="0">
          <el-input
            v-model="scope.row.mtrb_bnum"
            disabled
            maxlength="9"
            placeholder="请填写备品数量"
            show-word-limit
            @change="val => (scope.row.mtrb_bnum = new BigNumber(val).toFixed(0, 0))"
          >
          </el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_mtrb_price="scope">
        <el-form-item :prop="'modr_mtrb_list.' + scope.$index + '.prod_mtrb_price'" label-width="0">
          <el-input
            v-model="scope.row.prod_mtrb_price"
            maxlength="9"
            placeholder="暂无单价"
            show-word-limit
            @change="val => mtrbPriceChange(scope.$index)"
            @input="val => (scope.row.prod_mtrb_price = keep4Decimal(val))"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:supp_bid="scope">
        <el-form-item :prop="'modr_mtrb_list.' + scope.$index + '.supp_babbr'" label-width="0">
          <!--<el-select-->
          <!--  v-model="scope.row.supp_bid"-->
          <!--  filterable-->
          <!--  placeholder="暂无收货工厂"-->
          <!--  @change="val => (scope.row.supp_babbr = suppOptionList.find(x => x.supp_id === val).supp_abbr)"-->
          <!--&gt;-->
          <!--  <el-option v-for="item in suppOptionList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"></el-option>-->
          <!--</el-select>-->
          <el-input v-model="scope.row.supp_babbr" disabled show-word-limit></el-input>
        </el-form-item>
      </template>
      <!--<template v-slot:podr_no="scope">-->
      <!--  <el-form-item :prop="'modr_mtrb_list.' + scope.$index + '.podr_id'" label-width="0">-->
      <!--    <el-select-->
      <!--      v-model="scope.row.podr_id"-->
      <!--      placeholder="暂无成品采购合同号"-->
      <!--      @change="-->
      <!--        val => {-->
      <!--          let temp = scope.row.list.find(x => Number(x.value) === val);-->
      <!--          scope.row.supp_bid = Number(temp.permValue);-->
      <!--          scope.row.supp_babbr = temp.permLabel;-->
      <!--        }-->
      <!--      "-->
      <!--    >-->
      <!--      <el-option v-for="item in scope.row.list" :key="item.value" :label="item.label" :value="Number(item.value)"></el-option>-->
      <!--    </el-select>-->
      <!--  </el-form-item>-->
      <!--</template>-->
    </DynamicUTable>
    <el-dialog :visible.sync="dialogVisible" title="材料" width="70%">
      <MtrbImport ref="MtrbImport" :scon_id="modrForm.scon_id" :supp_id="modrForm.supp_id" @childChanel="childChanel" @childConfirm="childConfirm"></MtrbImport>
    </el-dialog>
  </div>
</template>
<script>
import { getNoCatch } from '@/api/request';
import MtrbImport from './MtrbImport.vue';
import { keep2Decimal, keep4Decimal, keep6Decimal, keepNumber } from '@assets/js/regExUtil';
import { BigNumber } from 'bignumber.js';
import { optnAPI } from '@api/modules/optn';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { subTableProperties } from '@/views/SalesManagement/ModrManage/modr';
import { cloneDeep } from 'lodash';
import { modrAPI } from '@api/modules/modr';
import { arrayDeRepeat, getArrayIds, objectArrayReduce } from '@assets/js/arrayUtils';

export default {
  name: 'modrMtrbList',
  inject: ['initData'],
  computed: {
    BigNumber() {
      return BigNumber;
    },
    isCopy() {
      let { dept_name, stff_id } = this.$cookies.get('userInfo');
      return dept_name === '辅料部' || stff_id === 1;
    },
    tableData() {
      return this.modrForm.modr_mtrb_list.filter(data => !this.search || data.supp_babbr.includes(this.search));
    }
  },
  props: {
    modrForm: {
      type: Object,
      required: true
    },
    suppOptionList: {
      type: Array,
      required: true
    },
    scon_id: Number,
    isShow: Boolean,
    podrOptionList: {
      type: Array,
      required: true
    }
  },
  components: {
    DynamicUTable,
    MtrbImport
  },

  created() {
    getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10024 }).then(({ data }) => {
      this.rateList = data.form.optn_cntt_list;
    });
    let userInfo = this.$cookies.get('userInfo');
    this.notFuliao = userInfo.dept_name !== '辅料部' || userInfo.stff_id === 1;
  },
  data() {
    return {
      dialogVisible: false,
      partLoadFlag: false,
      selectionsList: [],
      ilkList: [
        { id: 0, name: '新款' },
        { id: 1, name: '修改' },
        { id: 2, name: '翻单' }
      ],
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      rateList: [],
      subTableProperties: subTableProperties,
      sampleSubmissionDate: '',
      search: '',
      notFuliao: ''
    };
  },
  methods: {
    keepNumber,
    arrayDeRepeat,
    keep6Decimal,
    keep4Decimal,
    keep2Decimal,
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    //点击导入组件
    clickImport() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.$refs.MtrbImport.initData();
      }, 300);
    },
    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    // 确认传值
    childConfirm(value) {
      let val = JSON.parse(JSON.stringify(value));
      val = val.filter(item => this.modrForm.modr_mtrb_list.every(item1 => item.scon_mtrb_id !== item1.scon_mtrb_id));
      val.forEach(item => {
        item.prod_mtrb_price = new BigNumber(item.prod_mtrb_price).toFixed(4);
        item.mtrb_num = new BigNumber(item.scon_mtrb_num).toFixed(0);
        item.supp_bid = item.main_supp_id;
        item.supp_babbr = item.main_supp_abbr;
        item.mtrb_total_price = new BigNumber(item.mtrb_num).times(item.prod_mtrb_price).toFixed(4);
        if (item.mtrb_pctype) {
          this.rateList
            .filter(({ param1 }) => param1 === item.mtrb_pctype)
            .forEach(subItem => {
              let num = Number(item.mtrb_num);
              if (num >= Number(subItem.param3) && num < Number(subItem.param2)) {
                item.mtrb_bnum = new BigNumber(num).times(subItem.param4).div(100).toFixed(0, 0);
              }
            });
          item.mtrb_num_total = new BigNumber(item.mtrb_num).plus(item.mtrb_bnum || 0).toNumber();
        }
      });
      this.modrForm.modr_mtrb_list = this.modrForm.modr_mtrb_list.concat(val);
      this.$emit('mtrbPriceChange', objectArrayReduce(this.modrForm.modr_mtrb_list, 'mtrb_total_price'));
      this.dialogVisible = false;
    },
    //重新计算金额 发送给父组件
    mtrbPriceChange(index) {
      let modrMtrbListElement = this.modrForm.modr_mtrb_list[index];
      modrMtrbListElement.prod_mtrb_price = new BigNumber(modrMtrbListElement.prod_mtrb_price).toFixed(4);
      let temp = new BigNumber(modrMtrbListElement.mtrb_num);
      modrMtrbListElement.mtrb_num = temp.isFinite() ? temp.toFixed(0) : 0;
      modrMtrbListElement.mtrb_total_price = new BigNumber(modrMtrbListElement.mtrb_num).times(modrMtrbListElement.prod_mtrb_price).toFixed(4);
      this.rateList
        .filter(({ param1 }) => param1 === modrMtrbListElement?.mtrb_pctype)
        .forEach(item => {
          let num = Number(modrMtrbListElement.mtrb_num);
          if (num >= Number(item.param3) && num < Number(item.param2)) {
            modrMtrbListElement.mtrb_bnum = new BigNumber(num).times(item.param4).div(100).toFixed(0, 0);
          }
        });
      if (modrMtrbListElement) modrMtrbListElement.mtrb_num_total = new BigNumber(modrMtrbListElement.mtrb_num).plus(modrMtrbListElement.mtrb_bnum).toNumber();
      this.$emit('mtrbPriceChange', objectArrayReduce(this.modrForm.modr_mtrb_list, 'mtrb_total_price'));
    },
    // 删除每一行
    deleteMtrb() {
      this.modrForm.modr_mtrb_list = this.modrForm.modr_mtrb_list.filter(item => this.selectionsList.every(item1 => item.scon_mtrb_id !== item1.scon_mtrb_id));
      this.$emit('mtrbPriceChange', objectArrayReduce(this.modrForm.modr_mtrb_list, 'mtrb_total_price'));
    },
    async copySelectedColumnsToClipboard() {
      const columnsToCopy = [
        'mtrb_ilk', // 产品类型
        'mtrb_name', // 中文品名
        'mtrb_num', // 数量
        'supp_bid' // 收货工厂
      ];
      // 构建表头
      const headers = columnsToCopy
        .map(prop => {
          return this.subTableProperties.find(item => item.prop === prop)?.label || prop;
        })
        .join('\t');
      // let dataAsText = headers + '\n'; // 首先添加表头，末尾添加换行符
      let dataAsText = ''; // 首先添加表头，末尾添加换行符
      for (const row of this.modrForm.modr_mtrb_list) {
        let rowData = '';
        for (const col of columnsToCopy) {
          let value = row[col];
          if (value || value === 0) {
            if (col === 'mtrb_ilk') {
              value = this.ilkList.find(x => x.id === value).name;
            }
            if (col === 'supp_bid') {
              value = this.suppOptionList.find(x => x.supp_id === value).supp_abbr;
            }
            rowData += `${value}\t`; // 每个字段后添加制表符分隔
          } else rowData += `\t`;
        }
        dataAsText += rowData.slice(0, -1) + '\n';
      }
      try {
        await navigator.clipboard.writeText(dataAsText.trim());
        this.$message.success('已复制到剪贴板');
      } catch (err) {
        this.$message.error('复制到剪贴板失败，请重试');
        console.error('复制到剪贴板失败:', err);
      }
    },
    fillSubTableDate() {
      if (!this.sampleSubmissionDate) return this.$message.warning('请选择日期!');
      let selection = cloneDeep(this.selectionsList);
      if (selection.length === 0) return this.$message.warning('请选择数据!');
      modrAPI
        .edit_confirm_shipment({
          modr_id: this.modrForm.modr_id,
          modr_mtrb_ids: getArrayIds(this.selectionsList, 'modr_mtrb_id').toString(),
          modr_mtrb_redate: cloneDeep(this.sampleSubmissionDate) / 1000
        })
        .then(({ data }) => {
          this.$message.success('填充成功!');
          this.initData();
        });
    },
    split() {
      this.partLoadFlag = true;
      if (this.selectionsList.length === 0) return this.$message.warning('请选择数据!');
      if (this.selectionsList.length > 1) return this.$message.warning('只可选择单条数据进行拆分!');
      this.$prompt('', '请输入拆分数量', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\d+$/,
        inputErrorMessage: '请输入正确的数量',
        closeOnClickModal: false,
        inputValue: 1
      })
        .then(({ value }) => {
          let splitCopy1 = this.selectionsList[0];
          if (Number(value) >= Number(splitCopy1.mtrb_num)) return this.$message.warning('拆分的数量不能大于等于原数量!');
          splitCopy1.mtrb_num -= value;
          let splitCopy2 = cloneDeep(splitCopy1);
          splitCopy2.modr_mtrb_id = null;
          splitCopy2.mtrb_num = value;
          splitCopy2._XID = null;
          this.modrForm.modr_mtrb_list.push(splitCopy2);
          [splitCopy1, splitCopy2].forEach(copyItem => {
            this.rateList
              .filter(({ param1 }) => param1 === copyItem.mtrb_pctype)
              .forEach(item => {
                let num = Number(copyItem.mtrb_num);
                if (num >= Number(item.param3) && num < Number(item.param2)) {
                  copyItem.mtrb_bnum = new BigNumber(num).times(item.param4).div(100).toFixed(0, 0);
                }
              });
          });
          let sum = 0;
          this.modrForm.modr_mtrb_list.forEach(item => {
            item.prod_mtrb_price = this.helper.calcPrice(item.prod_mtrb_price, 4, 10000);
            item.mtrb_num = new BigNumber(item.mtrb_num).toFixed(0);
            item.mtrb_total_price = new BigNumber(item.mtrb_num).times(item.prod_mtrb_price).toFixed(4);
            sum += item.mtrb_total_price - 0;
          });
          this.$emit('mtrbPriceChange', sum);
          this.$refs.multiTable.clearSelection();
        })
        .finally(() => {
          setTimeout(() => {
            this.partLoadFlag = false;
          }, 500);
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
