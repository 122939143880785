<template>
  <div class="modrProdList">
    <div class="vg_mb_16">
      <el-button type="success" plain size="small" @click="clickAdd">新增</el-button>
      <el-button type="danger" plain size="small" @click="clickDel">删除</el-button>
    </div>
    <el-table :data="modrForm.modr_fees_list" border @selection-change="handleSelectionChange" show-summary :summary-method="getSummaries">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="费用名">
        <template slot="header">
          <span style="color: red">费用名</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_fees_list.' + scope.$index + '.modr_fees_name'" :rules="{ required: true, message: ' ' }">
            <el-input v-model.trim="modrForm.modr_fees_list[scope.$index].modr_fees_name" maxlength="20" show-word-limit placeholder="暂无费用名"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="金额(元)" prop="modr_fees_rmb">
        <template slot="header">
          <span style="color: red">金额(元)</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_fees_list.' + scope.$index + '.modr_fees_rmb'" :rules="{ required: true, message: ' ' }">
            <el-input
              @change="fessPriceChange(scope.$index)"
              v-model="modrForm.modr_fees_list[scope.$index].modr_fees_rmb"
              @input="modrForm.modr_fees_list[scope.$index].modr_fees_rmb = keep2DecimalContainNegative(modrForm.modr_fees_list[scope.$index].modr_fees_rmb)"
              @blur="modrForm.modr_fees_list[scope.$index].modr_fees_rmb = keepNotAloneNegative(modrForm.modr_fees_list[scope.$index].modr_fees_rmb)"
              maxlength="13"
              show-word-limit
              placeholder="暂无金额"
            ></el-input>
          </el-form-item>
        </template>
        <!-- oninput="value=value.replace(/^0+(\d)/, '$1').replace(/^\./, '0.').match(/^\d*(\.?\d{0,4})/g)[0] || ''"  -->
      </el-table-column>
      <el-table-column label="费用备注">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'modr_fees_list.' + scope.$index + '.modr_fees_remark'">
            <el-input v-model="modrForm.modr_fees_list[scope.$index].modr_fees_remark" maxlength="50" show-word-limit placeholder="暂无备注"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import { keep2DecimalContainNegative, keepNotAloneNegative } from '@assets/js/regExUtil';
export default {
  name: 'modrProdList',
  props: {
    modrForm: Object
  },
  data() {
    return {
      selectionsList: [],
      delList: []
    };
  },
  methods: {
    keepNotAloneNegative,
    keep2DecimalContainNegative,
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    clickAdd() {
      this.modrForm.modr_fees_list.push({
        modr_fees_name: null,
        modr_fees_usd: null,
        expe_remake: null
      });
    },
    clickDel() {
      if (this.selectionsList.length === 0) {
        return this.$message.info('至少选择一条信息');
      }
      this.$confirm('确定移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.selectionsList.forEach((item, index) => {
            this.modrForm.modr_fees_list.forEach((item2, index2) => {
              if (item === item2) {
                if (item2.destroy_flag !== undefined) {
                  item2.destroy_flag = 1;
                  this.delList.push(item2);
                }
                this.modrForm.modr_fees_list.splice(index2, 1);
                this.fessPriceChange();
              }
            });
          });
        })
        .catch(() => {});
    },
    //计算总金额
    fessPriceChange(index) {
      if (index || index === 0) {
        this.modrForm.modr_fees_list[index].modr_fees_rmb = new BigNumber(this.modrForm.modr_fees_list[index].modr_fees_rmb).toFixed(2);
      }
      let sum = Number(0);
      this.modrForm.modr_fees_list.forEach(item => {
        sum += Number(item.modr_fees_rmb);
      });
      this.$emit('feetPriceChange', new BigNumber(sum).toFixed(2));
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = new BigNumber(sums[index]).toFixed(4);
        } else {
          sums[index] = '';
        }
      });

      return sums;
    }
  }
};
</script>

<style lang="scss" scoped></style>
