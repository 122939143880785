<template>
  <div class="sconProdList">
    <el-row justify="space-between">
      <el-col :md="12">
        <el-button type="primary" plain size="small" @click="generateConfirm">确定</el-button>
        <el-button type="danger" plain size="small" @click="generateCancel">取消</el-button>
      </el-col>
    </el-row>
    <div class="vg_mb_16"></div>
    <el-table :data="ModrList" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="60" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="我司货号" prop="prod_no"> </el-table-column>
      <el-table-column label="材料编号" prop="mtrb_no"> </el-table-column>
      <el-table-column label="客户货号" prop="prod_cust_no"> </el-table-column>
      <el-table-column label="材料名称" prop="mtrb_name">
        <template slot-scope="scope">
          <span v-if="scope.row.mtrb_name">
            {{ scope.row.mtrb_name }}
          </span>
          <span v-else class="vg_9f9a9a">暂无</span>
        </template>
      </el-table-column>
      <el-table-column label="收货工厂" prop="supp_babbr">
        <template slot-scope="scope">
          <span v-if="scope.row.supp_babbr">
            {{ scope.row.supp_babbr }}
          </span>
          <span v-else class="vg_9f9a9a">暂无</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { modcAPI } from '@/api/modules/modc';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ImportProd',
  props: {
    ModrList: {
      type: Array,
      required: true
    },
    modrId: {
      type: Number,
      required: true
    }
  },
  components: {},
  data() {
    return {
      selectionsList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    // 取消生成
    generateCancel() {
      this.$emit('generateCancel');
    },
    // 确认生成
    generateConfirm() {
      if (this.selectionsList.length > 0) {
        this.$confirm('确定生成?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            let ids = [];
            for (let i = 0; i < this.selectionsList.length; i++) {
              ids.push(this.selectionsList[i].modr_mtrb_id);
            }
            let temp = {};
            temp.modr_id = this.modrId;
            temp.modr_mtrb_ids = ids.join(',');
            post(modcAPI.addModc, temp)
              .then(res => {
                if (res.data.code === 0) {
                  this.$message({
                    type: 'success',
                    message: '生成成功'
                  });
                  this.generateCancel();
                  this.jump('/modc_edit', {
                    key: UrlEncode.encode(
                      JSON.stringify({
                        perm_id: 136,
                        form_id: res.data.data.form_id
                      })
                    )
                  });
                } else {
                  let mg = res.data.msg;
                  let tp = 'error';
                  this.$message({ message: mg, type: tp });
                }
              })
              .catch(() => {});
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消生成'
            });
          });
      } else {
        this.$message.warning('至少选择一条数据');
      }
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    }
  }
};
</script>

<style scoped lang="scss"></style>
