import topic from '../topic';
export const modcAPI = {
  getModcs: topic.modcTopic + '/get_modcs',
  getModcById: topic.modcTopic + '/get_modc_by_id',
  addModc: topic.modcTopic + '/add_modc',
  editModc: topic.modcTopic + '/edit_modc',
  deleteModcByIds: topic.modcTopic + '/delete_modc_by_ids',
  exportModcPdfById: topic.modcTopic + '/pdf/export_modc_pdf_by_id',
  getModcRecord: topic.modcTopic + '/getModcRecord'
};
