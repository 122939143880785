<template>
  <div class="sconClauInfo">
    <el-row class="vg_mt_8">
      <el-col :md="8">
        <el-form-item label="测试样时间" :prop="'testspecimens_date'">
          <el-date-picker v-model="modrForm.testspecimens_date" clearable placeholder="选择日期" size="small" type="date" value-format="timestamp">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="大货样时间" :prop="'largesamples_date'">
          <el-date-picker v-model="modrForm.largesamples_date" clearable placeholder="选择日期" size="small" type="date" value-format="timestamp">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="箱规提供时间" :prop="'boxgauge_date'">
          <el-date-picker v-model="modrForm.boxgauge_date" clearable placeholder="选择日期" size="small" type="date" value-format="timestamp"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <div style="display: flex">
          <div style="width: 120px; text-align: right; padding-right: 12px">
            <div>验货标准</div>
            <el-link class="vg_pointer" type="primary" @click="clickDialog(1)" :disabled="isShow">选择</el-link>
          </div>
          <el-input v-model="modrForm.modr_standard" type="textarea" placeholder="暂无验货标准"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <div style="display: flex">
          <div style="width: 120px; text-align: right; padding-right: 12px">
            <div>条款内容</div>
            <el-link class="vg_pointer" type="primary" @click="clickDialog(2)" :disabled="isShow">选择</el-link>
          </div>
          <el-input v-model="modrForm.clau_content" maxlength="5000" placeholder="暂无条款内容" show-word-limit type="textarea"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <div style="display: flex">
          <div style="width: 120px; text-align: right; padding-right: 12px">
            <div>合同注意事项</div>
            <el-link :disabled="isShow" class="vg_pointer" type="primary" @click="clickDialog(4)">选择</el-link>
          </div>
          <el-input v-model="modrForm.modr_remark" clearable placeholder="暂无合同注意事项" show-word-limit type="textarea"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="24">
        <div style="display: flex">
          <div style="width: 120px; text-align: right; padding-right: 12px">
            <div>送货地址</div>
            <el-link class="vg_pointer" type="primary" @click="clickDialog(3)" :disabled="isShow">选择</el-link>
          </div>
          <el-input v-model="modrForm.modr_address" type="textarea" :rows="3" show-word-limit placeholder="请填写送货地址"></el-input>
        </div>
      </el-col>
    </el-row>

    <el-dialog :title="title" :visible.sync="dialogClauVisible" width="70%">
      <ImportClau @importClauChange="importClauChange" />
    </el-dialog>
  </div>
</template>

<script>
import ImportClau from './ImportClau.vue';

export default {
  name: 'SconCaluInfo',
  components: {
    ImportClau
  },
  props: {
    modrForm: Object,
    isShow: Boolean
  },
  data() {
    return {
      dialogClauVisible: false,
      title: ''
    };
  },
  methods: {
    importClauChange(row) {
      this.dialogClauVisible = false;
      this.$emit('sconClauChange', { type: this.title, value: row });
    },
    clickDialog(value) {
      switch (value) {
        case 1:
          this.title = '验货标准';
          break;
        case 2:
          this.title = '条款内容';
          break;
        case 3:
          this.title = '送货地址';
          break;
        case 4:
          this.title = '合同注意事项';
          break;
        default:
          break;
      }
      this.dialogClauVisible = true;
    }
  }
};
</script>

<style></style>
